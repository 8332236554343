import { Controller } from "@hotwired/stimulus";
import "@rails/actiontext";
import Trix from "trix";

// Connects to data-controller="trix-editor"
export default class extends Controller {
  static targets = [
    "mainEditor",
    "selectVideoButton",
    "mainEditorVideoVariable",
    "selectVideoBtn",
    "modal",
    "modalEditor",
    "modalEditorVideoVariable",
    "bodyInJsonField",
  ];
  static values = {
    videoPlaceholder: String,
    videos: Array,
    convertToJson: {
      type: Boolean,
      default: false,
    },
  };

  connect() {
    this.initializeEditor(this.mainEditorTarget)
    if (this.hasModalEditorTarget) {
      this.initializeEditor(this.modalEditorTarget)
    }
  }

  initializeEditor(element) {
    element.addEventListener("trix-before-initialize", () => {
      this.configureTrixAttributes();
    })

    element.addEventListener("trix-initialize", () => {
      this.configureTrixAttributes();
      // Ensure Trix is initialized before adding custom toolbar buttons and dialogs
      const toolbar = element.toolbarElement.querySelector(".trix-button-group--block-tools");
      const dialogs = element.toolbarElement.querySelector(".trix-dialogs");
      const editor = element.getAttribute('data-trix-editor-target')

      if (this.convertToJsonValue) {
        let json = JSON.stringify(element.editor)
        this.bodyInJsonFieldTarget.value = json
      }

      if (toolbar && dialogs) {
        this.addCustomToolbarButtons(toolbar)
        // Add custom button
        this.addSelectVideoPlaceholder(editor, toolbar, dialogs)
        // if (this.videosValue?.length) {
        //   this.configureVideo(editor, element)
        // }
      }
    });

    if (this.convertToJsonValue) {
      this.trixToJson(element)
    }
  }

  addCustomToolbarButtons(toolbar) {
    // Add underline and heading buttons
    const buttons = `
      <button type="button" class="trix-button" data-trix-attribute="underline" title="Underline" tabindex="-1">U</button>
      <button type="button" class="trix-button" data-trix-attribute="small" title="Small Text" tabindex="-1">Small</button>
      <button type="button" class="trix-button" data-trix-attribute="large" title="Large Text" tabindex="-1">Large</button>
      <button type="button" class="trix-button" data-trix-attribute="heading2" title="Heading 2" tabindex="-1">H2</button>
      <button type="button" class="trix-button" data-trix-attribute="heading3" title="Heading 3" tabindex="-1">H3</button>
    `;

    // Add custom buttons to the toolbar
    toolbar.insertAdjacentHTML("afterbegin", buttons);
  }

  // configureVideo(editor, element) {
  //   const buttonHTML = `
  //   <div class="relative">
  //     <button type="button" class="trix-button" data-action="attach-video" title="Attach Video">
  //       📹 Attach Video
  //     </button>
  //     <div id="video-dropdown" class="hidden absolute left-0 z-50 mt-6 w-64 bg-white border border-gray-300 rounded-lg shadow-lg">
  //       <select id="video-selector" class="w-full p-2 text-sm text-gray-700 border-0 focus:outline-none">
  //         <option value="" disabled selected>Select a video</option>
  //       </select>
  //     </div>
  //   </div>
  //   `;

  //   const toolbar = element.toolbarElement.querySelector(".trix-button-group--file-tools");
  //   toolbar.insertAdjacentHTML("beforeend", buttonHTML);

  //   document.querySelector("[data-action='attach-video']").addEventListener("click", () => {
  //     const dropdown = document.getElementById("video-dropdown");
  //     const selector = document.getElementById("video-selector");
  //     if (selector.querySelectorAll("option").length === 1) {
  //       this.videosValue.forEach((video) => {
  //         const option = document.createElement("option");
  //         option.value = video.id;
  //         option.textContent = video.name;
  //         selector.appendChild(option);
  //       })
  //     }
  //     dropdown.classList.toggle("hidden");
  //   })


  //   document.getElementById("video-selector").addEventListener("change", (event) => {
  //     const videoId = event.target.value;  
  //     if (videoId) {
  //       const video = this.videosValue.find((v) => v.id == videoId);
  //       if (video) {
  //         fetch(`/mdi/videos/${video.id}/show_for_attachment`)
  //           .then((response) => response.json())
  //           .then((json) => {
  //             let attachment = new Trix.Attachment({
  //               sgid: json.sgid,
  //               content: json.content,
  //               "attachment-type": "mdi_video",
  //               "data-trix-serialized-attributes": {
  //                 "mux-playback-id": video.playback_id,
  //                 "mux-video-id": video.id
  //               }
  //             });
  //             element.editor.insertAttachment(attachment)
  //           })
  //       }
  //     }
  //     event.target.value = "";
  //     document.getElementById("video-dropdown").classList.add("hidden");
  //   })
  // }

  configureTrixAttributes() {
    // Underline
    Trix.config.textAttributes.underline = {
      tagName: "u", // HTML tag for underline
    };

    // Headings
    Trix.config.blockAttributes.heading2 = {
      tagName: "h2",
      terminal: true,
      breakOnReturn: true, // Create new block on enter
      group: false,
    };

    Trix.config.blockAttributes.heading3 = {
      tagName: "h3",
      terminal: true,
      breakOnReturn: true, // Create new block on enter
      group: false,
    };

    // Font Size
    Trix.config.textAttributes.small = {
      style: { fontSize: "12px" },
      inheritable: true,
      parser: (element) => {
        return element.style.fontSize === "12px";
      },
    };

    Trix.config.textAttributes.large = {
      style: { fontSize: "20px" },
      inheritable: true,
      parser: (element) => {
        return element.style.fontSize === "20px";
      },
    };
  }

  trixToJson(element) {
    element.addEventListener("trix-change", (e) => {
      let json = JSON.stringify(e.target.editor)
      this.bodyInJsonFieldTarget.value = json
    })
  }

  placeholderVideosOptions() {
    let options = '<option value="">Select Videos</option>';
    this.videosValue.forEach((video) => {
      options += `<option value="${video.id}">${video.name}</option>`;
    })
    return options;
  }

  addSelectVideoPlaceholder(editor, toolbar, dialogs) {
    if(this.videosValue?.length) {
      const btn = `
        <button type="button" class="trix-button" data-trix-attribute="video_placeholder" title="VideoPlchldr" tabindex="-1">📹 Attach Video</button>
      `;
      toolbar.insertAdjacentHTML("afterbegin", btn);

      // Add custom dialog
      const dialog = `
        <div class="trix-dialog trix-dialog--placeholder" data-trix-dialog="video_placeholder" data-trix-dialog-attribute="video_placeholder">
          <div class="trix-dialog__link-fields">
            <select name="video_variable_tag" style="margin-right: 10px" data-trix-editor-target="${editor}VideoVariable">
              ${this.placeholderVideosOptions()}
            </select>
            <div class="trix-button-group">
              <input type="button" id="selectVideoBtn" class="trix-button trix-button--dialog" value="Select" data-action="click->trix-editor#setVideoSelected" data-trix-editor-target="selectVideoBtn" data-trix-method="removeAttribute" data-source="${editor}">
              <input type="button" class="trix-button trix-button--dialog" value="Cancel" data-trix-method="removeAttribute">
            </div>
          </div>
        </div>
      `;
      dialogs.insertAdjacentHTML("beforeend", dialog);
    }
  }

  setVideoSelected(e) {
    const editor = e.currentTarget.getAttribute('data-source')
    let videoId;
    let editorData;
    let editorElement;
    if(editor == "mainEditor") {
      videoId = this.mainEditorVideoVariableTarget.value;
      editorElement = this.mainEditorTarget
      editorData = this.mainEditorTarget.editor
    } else if(editor == "modalEditor") {
      const value = this.modalEditorVideoVariableTarget.value;
      editorElement = this.modalEditorTarget
      editorData = this.modalEditorTarget.editor
    }

    if (videoId) {
      const video = this.videosValue.find((v) => v.id == videoId);
      if (video) {
        fetch(`/mdi/videos/${video.id}/show_for_attachment`)
          .then((response) => response.json())
          .then((json) => {
            let attachment = new Trix.Attachment({
              sgid: json.sgid,
              content: json.content,
              "attachment-type": "mdi_video",
              "data-trix-serialized-attributes": {
                "mux-playback-id": video.playback_id,
                "mux-video-id": video.id
              }
            });

            const activeTools = editorElement.toolbarElement.querySelectorAll(".trix-active");
            activeTools?.forEach((tool) => {
              const toolName = tool.getAttribute("data-trix-attribute");
              if (toolName) {
                editorData.deactivateAttribute(toolName);
              }
            })

            editorData.insertAttachment(attachment);
          })
      }
    }
  }

  openModal() {
    const modal = this.modalTarget;
    // Get the HTML content from the main editor
    const currentContent = this.mainEditorTarget.innerHTML;

    // Set the content in the modal editor
    this.modalEditorTarget.editor.loadHTML(currentContent);
    modal.classList.remove("hidden");
    modal.classList.add("flex");
  }

  closeModal() {
    const modal = this.modalTarget;
    modal.classList.remove("flex");
    modal.classList.add("hidden");
  }

  copyToMainEditor() {
    const currentContent = this.modalEditorTarget.innerHTML;
    this.mainEditorTarget.editor.loadHTML(currentContent);
    // Manually dispatch an input event to trigger the auto-submit action
    const event = new Event('input', {
      bubbles: true,
      cancelable: true,
    });
    this.mainEditorTarget.dispatchEvent(event);

    this.closeModal()
  }
}

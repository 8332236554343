import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="submit-on-enter"
export default class extends Controller {
  connect() {
    document.addEventListener("keydown", this.handleKeydown);
  }

  disconnect() {
    document.removeEventListener("keydown", this.handleKeydown);
  }

  handleKeydown = (event) => {
    if (event.key === "Enter" && event.target.tagName !== "TEXTAREA") {
      event.preventDefault();
      this.element.requestSubmit();
    }
  };
}

